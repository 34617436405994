import React, { useEffect, Component, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from '../../components/header/header.prod.jsx';
import Footer from '../../components/footer/footer.prod.jsx';
import {Card, Avatar, Tabs, Breadcrumb} from 'flowbite-react';
import { HomeIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import axios from 'axios';



export const KmsPresent = (props) => {
  useEffect(() => {
    AOS.init();
  })

  return (
    <div>
      
        <Header isLogin={props.isLogin} userName={props.userName} userEmail={props.userEmail} userImage={props.userImage} />

	<div class="nhb bg-blue-900 bg-center bg-fixed bg-no-repeat bg-center bg-cover h-screen relative">
		<div class="h-screen bg-opacity-50 bg-black flex items-center justify-center">
			<div class="mx-2 text-center">
        
				<h1 class="text-white font-extrabold text-4xl xs:text-5xl md:text-6xl leading-relaxed" data-aos="fade-up" data-aos-duration="1000">
        <span class="bg-blue-100 text-blue-500 rounded-lg text-xl px-2 py-2">창조팀 Kaggle 활용프로젝트</span><br/><br/>
          욕설 데이터를 활용한<br/>
          욕설 판단 AI
           </h1>
        </div>
    </div>
</div>
<div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center" data-aos="zoom-in" data-aos-duration="1000">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
            <h2 className="text-base font-semibold leading-7 text-blue-500">사용할 데이터셋</h2>
            Korean Hate Speech Dataset
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-200">
국내 혐오 커뮤니티에서 수집된 데이터를 기반으로 한 욕설 데이터셋입니다.<br/>
이 데이터셋과 Orange3의 Text 확장을 활용하여 자연어를 처리합니다.
          </p>
        </div>
        <div className="text-white mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl" data-aos="zoom-in" data-aos-duration="1000">
참고자료 : https://orangedatamining.com/blog/2019/2019-07-02-data-science-made-easy/<br/>
원본데이터셋 : https://www.kaggle.com/datasets/captainnemo9292/korean-hate-speech-dataset<br/>
상세계획 : https://docs.google.com/document/d/14UaTKSGiIhyFf8jxeSkhimvi1xrFzosB6S3b5ZC5Fy8/edit?usp=sharing

        </div>
      </div>
    </div>
        <Footer/>
   </div>
    
  );
};
