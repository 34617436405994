import React, { useRef, useEffect, Component, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

import axios from 'axios';

import AOS from "aos";
import "aos/dist/aos.css";

import Header from '../../components/header/header.prod.jsx';
import Footer from '../../components/footer/footer.prod.jsx';
import { Autoplay, Pagination, Navigation } from 'swiper';

import {Card, Avatar, Tabs} from 'flowbite-react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { ChevronRightIcon } from '@heroicons/react/20/solid'

export const HomePage = (props) => {
  useEffect(() => {
    AOS.init();
  })

  const people = [
    {
      name: '30911 박동하',
      role: '#레코드라이터 #스토리텔러 #암거도안함',
      imageUrl:
        '/members/8C5DBE0F-F473-4548-AD6E-59CA2204ACDB.jpeg',
    },
    {
      name: '30912 손가현',
      role: '#엔지니어2 #임과_동시에_레?코드라이터',
      imageUrl:
        '/members/4963C757-AF94-4802-8C7B-F135FF67C008.jpeg',
    },
    {
      name: '30913 이규빈',
      role: '#팀장 #전직엔지니어 #이런거만듬',
      imageUrl:
        '/members/95803F98-3893-43BB-8AC1-CCEA9943F7F6.jpeg',
    },
    {
      name: '30914 이동건',
      role: '#엔지니어1 #열일중',
      imageUrl:
        '/members/D16D9737-8AE4-4752-9401-E0B58E753068.jpeg',
    },
    // More people...
  ]

  return (
    <div>
        <Header isLogin={props.isLogin} userName={props.userName} userEmail={props.userEmail} userImage={props.userImage} />
        <div class="nhb bg-center bg-fixed bg-no-repeat bg-center bg-cover h-screen relative" style={{ backgroundImage: `url('/idea-concept-with-light-bulb.jpg')` }}>
		<div class="h-screen bg-opacity-50 bg-white backdrop-blur-lg flex items-center justify-center">
			<div class="mx-2 text-center">

				<h1 class="text-black font-extrabold text-4xl xs:text-5xl md:text-8xl leading-relaxed" data-aos="fade-up" data-aos-duration="1000">
        환영합니다!<br/>
강신중 <span class="text-blue-500">3-9반 기술 팀</span><br/>
<span class="text-blue-500">창2적인조</span> 입니다.
           </h1>
           <p></p>
        </div>
    </div>
</div>
<div className="relative isolate overflow-hidden bg-gray-100 py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg" data-aos="fade-right" data-aos-duration="1000">
            <h2 className="text-4xl font-bold tracking-tight text-black sm:text-5xl">팀명 설정 이유</h2>
            <p className="mt-4 text-xl leading-8 text-gray-900">
            기술시간 동안 창의적인 것들을 많이 만들고 싶었기 때문에
팀명을 "창2적인조"로 정하게 되었습니다.
            </p>
          </div>
        </div>
      </div>
      <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#a5ffbe] to-[#00ffff] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl" data-aos="fade-right" data-aos-duration="1000">
          <h2 className="text-4xl font-bold tracking-tight text-gray-100 sm:text-5xl">팀원 소개</h2>
          <p className="mt-6 text-lg leading-8 text-gray-100">
            우리 조의 팀원을 소개합니다.
          </p>
        </div>
        <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          {people.map((person) => (
            <li key={person.name}>
              <div className="flex items-center gap-x-6">
                <img className="h-16 w-16 rounded-full" src={person.imageUrl} alt="" />
                <div>
                  <h3 className="text-base font-semibold leading-7 text-xl tracking-tight text-gray-100">{person.name}</h3>
                  <p className="text-md font-semibold leading-6 text-blue-200">{person.role}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
        <Footer/>
   </div>
    
  );
};
