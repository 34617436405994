import React, { useEffect, useState } from "react";
import "./App.css";
import './index.css';
import { AuthPage } from "./pages/Auth/AuthPage";
import { ErrorPage } from "./pages/Error/errorPage";
import { TravelPage } from "./pages/app/travel";
import { AboutpPage } from "./pages/app/aboutplus";
import { AboutmPage } from "./pages/app/aboutmplus";
import { AboutcPage } from "./pages/app/aboutcplus";
import { AboutvPage } from "./pages/app/aboutvplus";
import { AboutgPage } from "./pages/app/aboutgplus";
import { KmsPresent } from "./pages/app/kmspresent"
import { Routes, Route, useSearchParams, useNavigate } from "react-router-dom";
import { HomePage } from "./pages/home/home.page";
import { useCookies } from "react-cookie";
import Axios from "axios";

function App() {
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(['sessionID']);

  const [user, setUser] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  let [userName, setUserName] = useState("");
  let [userEmail, setUserEmail] = useState("");
  let [userImg, setUserImg] = useState("");

  useEffect(() => {if (searchParams.get("sessionID") !== null) {
    removeCookie('sessionID');
    setCookie('sessionID', searchParams.get("sessionID"));
    navigate('/');
    window.location.reload();
  }});

  //Requesting on http://localhost:5000/auth/login/success and getting users data.
/*  useEffect(() => {
    const token = cookies.sessionID;
    Axios.get("https://mapi.pcor.me/api/auth/login/success.php?sessionID="+token, {
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((res) => {
        if (res.data.status == "success") {
          setUserName(res.data.user[0]);
          setUserEmail(res.data.user[1]);
          setUserImg(res.data.user[2]);
        } else {
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);
*/
  return (
    <div className="App">
      <Routes>
        <Route path="/signup" element={<AuthPage  />} />
        <Route path="/aboutPlus" element={<AboutpPage  />} />
        <Route path="/metroPlus" element={<AboutmPage  />} />
        <Route path="/feedback" element={<AboutcPage  />} />
        <Route path="/virtualPlus" element={<AboutvPage  />} />
        <Route path="/log" element={<AboutgPage  />} />
        <Route path="/todayTravel" element={<TravelPage  />} />
        <Route path="/kmspresent" element={<KmsPresent  />} />
        <Route path="/" element={<HomePage isLogin={userName.length > 0 ? true:false} userName={userName} userEmail={userEmail} userImage={userImg} />} />
        <Route path='*' element={<ErrorPage />}/>
      </Routes>
    </div>
  );
}

export default App;
