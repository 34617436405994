import React, { Component } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';

class Graph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      )
    // 여기서 TXT 파일을 읽고 데이터를 파싱합니다.
    // 예를 들어, fetch 또는 axios를 사용하여 서버에서 파일을 가져오거나,
    // 로컬 파일 업로드를 지원하는 방식을 사용할 수 있습니다.

    // 아래는 가상의 데이터 예제입니다.
    const rawData = `3760                              
    3759                              
    3759                              
    3759                              
    3760                              
    3760                              
    3759                              
    3760                              
    3760                              
    3759                              
    3759                              
    3759                              
    3760                              
    3760                              
    3759                              
    3760                              
    3759                              
    3760                              
    3759                              
    3760                              
    3759                              
    3759                              
    3759                              
    3760                              
    3759                              
    3760                              
    3760                              
    3761                              
    3759                              
    3760                              
    3760                              
    3760                              
    3760                              
    3760                              
    3760                              
    3760                              
    3760                              
    3760                              
    3760                              
    3760                              
    3761                              
    3760                              
    3760                              
    3761                              
    3761                              
    3760                              
    3760                              
    3760                              
    3761                              
    3760                              
    3760                              
    3760                              
    3760                              
    3761                              
    3760                              
    3760                              
    3760                              
    3760                              
    3761                              
    3760                              
    3760                              
    3761                              
    3760                              
    3760                              
    3761                              
    3760                              
    3759                              
    3760                              
    3760                              
    3760                              
    3760                              
    3759                              
    3759                              
    3759                              
    3760                              
    3760                              
    3760                              
    3759                              
    3760                              
    3759                              
    3760                              
    3760                              
    3759                              
    3759                              
    3760                              
    3760                              
    3760                              
    3761                              
    3759                              
    3760                              
    3759                              
    3761                              
    3761                              
    3760                              
    3759                              
    3759                              
    3760                              
    3760                              
    3760                              
    3760                              
    3760                              
    3760                              
    3760                              
    3759                              
    3759                              
    3760                              
    3760                              
    3760                              
    3760                              
    3759                              
    3760                              
    3760                              
    3761                              
    3760                              
    3760                              
    3761                              
    3761                              
    3762                              
    3761                              
    3763                              
    3762                              
    3762                              
    3762                              
    3762                              
    3762                              
    3761                              
    3762                              
    3763                              
    3761                              
    3762                              
    3761                              
    3761                              
    3762                              
    3758                              
    3759                              
    3760                              
    3761                              
    3768                              
    3770                              
    3772                              
    3773                              
    3774                              
    3773                              
    3774                              
    3773                              
    3774                              
    3772                              
    3771                              
    3758                              
    3169                              
    1520                              
    924                              
    839                              
    819                              
    814                              
    810                              
    811                              
    811                              
    813                              
    813                              
    816                              
    819                              
    821                              
    823                              
    826                              
    827                              
    825                              
    824                              
    823                              
    823                              
    822                              
    821                              
    824                              
    824                              
    824                              
    824                              
    816                              
    776                              
    694                              
    625                              
    567                              
    529                              
    489                              
    430                              
    382                              
    367                              
    385                              
    425                              
    474                              
    520                              
    564                              
    691                              
    753                              
    780                              
    790                              
    798                              
    800                              
    804                              
    813                              
    812                              
    812                              
    813                              
    813                              
    814                              
    814                              
    813                              
    818                              
    823                              
    825                              
    839                              
    869                              
    955                              
    1573                              
    2975                              
    3741                              
    3767                              
    3771                              
    3773                              
    3773                              
    3774                              
    3774                              
    3773                              
    3774                              
    3774                              
    3775                              
    3773                              
    3772                              
    3768                              
    3762                              
    3762                              
    3761                              
    3761                              
    3763                              
    3762                              
    3761                              
    3761                              
    3762                              
    3761                              
    3762                              
    3761                              
    3762                              
    3761                              
    3762                              
    3761                              
    3761                              
    3762                              
    3761                              
    3761                              
    3761                              
    3762                              
    3765                              
    3771                              
    3772                              
    3773                              
    3774                              
    3775                              
    3774                              
    3774                              
    3772                              
    3715                              
    1760                              
    1006                              
    884                              
    860                              
    859                              
    861                              
    862                              
    860                              
    860                              
    859                              
    859                              
    859                              
    859                              
    859                              
    857                              
    859                              
    860                              
    860                              
    859                              
    860                              
    861                              
    862                              
    864                              
    869                              
    872                              
    879                              
    941                              
    1289                              
    2458                              
    3736                              
    3771                              
    3773                              
    3773                              
    3775                              
    3772                              
    3774                              
    3773                              
    3773                              
    3768                              
    3762                              
    3760                              
    3762                              
    `;

    const data = rawData.split('\n').map((line) => parseInt(line, 10));

    this.setState({ data });
  }

  render() {
    const { data } = this.state;

    const chartData = {
      labels: Array.from({ length: data.length }, (_, i) => i + 1),
      datasets: [
        {
          label: 'Serial Data Recieved',
          data: data,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
        },
      ],
    };

    return (
      <div>
        <h2 className="font-bold">적외선으로 받은 값의 그래프</h2>
        <Line data={chartData} />
      </div>
    );
  }
}

export default Graph;
