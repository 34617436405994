import React, { useRef, useState, useEffect } from "react";
import Header from '../../components/header/header.prod.jsx';
import Footer from '../../components/footer/footer.prod.jsx';
import {Card, Avatar, Tabs, Breadcrumb} from 'flowbite-react';
import { HomeIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { LightBulbIcon, CursorArrowRaysIcon, CpuChipIcon, CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import Iframe from 'react-iframe';

export const AboutpPage = (props) => {



  return (
    <div>
      
        <Header isLogin={props.isLogin} userName={props.userName} userEmail={props.userEmail} userImage={props.userImage} />
        <div class="mx-auto w-full max-w-screen-xl">
        <main
		class="my-1 pt-2 pb-2 px-4 md:px-10 flex-1 rounded-l-lg
		transition duration-500 ease-in-out overflow-y-auto">

<Breadcrumb class="pt-6 md:px-4" aria-label="Default breadcrumb example">
  <Breadcrumb.Item
  >
    메인
  </Breadcrumb.Item>
  <Breadcrumb.Item>
  수행평가
  </Breadcrumb.Item>
</Breadcrumb>
<h3
					class="flex items-center pt-6 md:px-4 text-3xl font-bold
					capitalize dark:text-gray-300">
            
					<span>수행평가</span>
				</h3>
<p class="flex items-center pt-4 pb-12 md:px-4 font-semibold text-lg
					capitalize dark:text-gray-300">
            수행평가 내역을 확인하여 보세요</p>

      </main>
      </div>
      <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-blue-600">수행평가 ONE</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">RC(Radio Control) 차 제작</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                라디오 통신을 통해 전진, 후진, 좌회전, 우회전, 제동이 가능한 무선조종 차량을 제작하였다.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                      <Iframe url="https://www.youtube.com/embed/b163MMC-EmY"
        id=""
        className="w-[48rem] aspect-video max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
        display="block"
        position="relative"/>
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CpuChipIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
                  <span>
                    Micro.bit 2개 (컨트롤, 주행), Marqueen Plus
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CursorArrowRaysIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">A를 누르면 전진, B를 누르면 후진, 왼쪽 틸팅을 통해 좌회전, 오른쪽 틸팅을 통해 우회전</strong>
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LightBulbIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">특별한 기능</strong> Micro.bit 5x5 LED를 통한 상태 표시 및 Marqueen Plus 8색 LED를 통한 주행 여부 확인
                  </span>
                </li>
              </ul>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">소스코드 확인하러 가기</h2>
              <p className="mt-6">
                19번 채널으로 설정 및 부팅 시 보라색 (Violet) LED 표시, LED에 "Welcome"표시를 통한 정상 부팅 확인<br/>
                blinkLED 변수를 통해 LED 깜빡임 효과 적용<br/>
                제동시 적색 LED, 주행시 청색 LED  표출<br/>
                recievedNumber의 값을 통해 방향의 조정 (전/후진 시 왼쪽 바퀴, 오른쪽 바퀴를 순차 움직임으로써 평형 유지)<br/><br/>
                <a href="https://github.com/10kwon/schoolMicrobit-0901" class="mt-3 px-3 bg-blue-500 font-bold text-white text-center m-auto py-2 rounded-xl transform ease-in duration-100 active:scale-95 hover:bg-blue-700">
              코드 다운로드</a>   
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-blue-600">수행평가 TWO</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">초음파 센서를 통한 물체감지 차량 제작</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                초음파 센서로 물체 감지 시 정지가 가능한 무선조종 차량을 제작하였다.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                      <Iframe url="https://www.youtube.com/embed/VlOXOvMEUxk"
        id=""
        className="w-[48rem] aspect-video max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
        display="block"
        position="relative"/>
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CpuChipIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
                  <span>
                    Micro.bit 2개 (컨트롤, 주행), Marqueen Plus
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CursorArrowRaysIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">A를 누르면 전진, B를 누르면 후진, 왼쪽 틸팅을 통해 좌회전, 오른쪽 틸팅을 통해 우회전, A+B를 통해 제동</strong>
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CursorArrowRaysIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">초음파 센서 활용</strong> 물체가 10cm 이하의 거리를 두고 있으면 모터 정지 및 우측으로 회전
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LightBulbIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">특별한 기능</strong> Micro.bit 5x5 LED를 통한 상태 표시 및 Marqueen Plus 8색 LED를 통한 주행 여부 확인
                  </span>
                </li>
              </ul>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">소스코드 확인하러 가기</h2>
              <p className="mt-6">
                19번 채널으로 설정 및 부팅 시 보라색 (Violet) LED 표시, LED에 "Welcome"표시를 통한 정상 부팅 확인<br/>
                blinkLED 변수를 통해 LED 깜빡임 효과 적용<br/>
                제동시 적색 LED, 주행시 청색 LED  표출<br/>
                물체가 10cm 이하의 거리를 두고 있으면 모터 정지 및 우측으로 회전<br/>
                recievedNumber의 값을 통해 방향의 조정 (전/후진 시 왼쪽 바퀴, 오른쪽 바퀴를 순차 움직임으로써 평형 유지)<br/><br/>
                <a href="https://github.com/ldgex1027/SchoolMicroBit-0901" class="mt-3 px-3 bg-blue-500 font-bold text-white text-center m-auto py-2 rounded-xl transform ease-in duration-100 active:scale-95 hover:bg-blue-700">
              코드 다운로드</a>   
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-blue-600">수행평가 THREE</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">IR (Infrared) 차 제작</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                적외선을 통해 자율주행하는 차량을 제작하였다.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                      <Iframe url="https://www.youtube.com/embed/6xXOwiGkKxw"
        id=""
        className="w-[48rem] aspect-video max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
        display="block"
        position="relative"/>
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CpuChipIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
                  <span>
                    Micro.bit 1개 (주행), Marqueen Plus
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LightBulbIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">특별한 기능</strong> Micro.bit 5x5 LED를 통한 상태 표시 및 Marqueen Plus 8색 LED를 통한 방향표시 (L/R트랙커 인식에 따른 Cyan LED 표출)
                  </span>
                </li>
              </ul>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">소스코드 확인하러 가기</h2>
              <p className="mt-6">
                부팅 시 적색(Red)-보라색 (Violet) 그라데이션 LED 표시, LED에 "♥"표시를 통한 정상 부팅 확인<br/>
                주행시 방향에 따른 청색 LED 표출<br/>
                Grayscale 3000 여부에 따라 방향 인지<br/><br/>
                <a href="/microbit-Code.hex" class="mt-3 px-3 bg-blue-500 font-bold text-white text-center m-auto py-2 rounded-xl transform ease-in duration-100 active:scale-95 hover:bg-blue-700">
              컴파일된 코드 다운로드</a>   
              <a href="https://github.com/10kwon/irsensor" class="mt-3 px-3 text-gray-500 text-xs text-center m-auto py-2 rounded-xl transform ease-in duration-100 active:scale-95">
              코드 다운로드 (MakeCode)</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
        <Footer/>
   </div>
    
  );
};
