import React, { useRef, useState, useEffect } from "react";
import Header from '../../components/header/header.jsx';
import Footer from '../../components/footer/footer.jsx';
import {Card, Avatar, Tabs, Breadcrumb} from 'flowbite-react';
import { HomeIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import axios from 'axios';

export const TravelPage = (props) => {
  const [boardData, setBoardData] = useState([]);
  useEffect(() => {
    axios.get('https://mapi.pcor.me/api/board/list.php?id=50&page=2')
      .then(response => setBoardData(response.data) && console.log(boardData))
      .catch(error => console.log(error));
  }, []);
  

  return (
    <div>
      
        <Header isLogin={props.isLogin} userName={props.userName} userEmail={props.userEmail} userImage={props.userImage} />
        <div class="mx-auto w-full max-w-screen-xl">
        <main
		class="my-1 pt-2 pb-2 px-4 md:px-10 flex-1 rounded-l-lg
		transition duration-500 ease-in-out overflow-y-auto">

<Breadcrumb class="pt-6 md:px-4" aria-label="Default breadcrumb example">
  <Breadcrumb.Item
  >
    메인
  </Breadcrumb.Item>
  <Breadcrumb.Item>
  {boardData.board.name}
  </Breadcrumb.Item>
</Breadcrumb>
<h3
					class="flex items-center pt-6 md:px-4 text-3xl font-bold
					capitalize dark:text-gray-300">
            
					<span>{boardData.board.name}</span>
				</h3>
<p class="flex items-center pt-4 pb-12 md:px-4 font-semibold text-lg
					capitalize dark:text-gray-300">
            {boardData.board.description}</p>



<div class="pt-1 pb-1 md:px-4">
<Card href="#" className="mb-2">
  <h5 className="truncate md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
    글 작성 테스트
  </h5>
  <p className="font-normal text-gray-700 dark:text-gray-400">
  <div className="text-xs md:text-sm flex flex-wrap gap-2">
  <Avatar rounded={true} className="h-6 md:h-10" /> ㅁㄴㅇㅁㄴㅇㅁㄴㅇ)<br/>
  조회수 34
</div>
  </p>
</Card>

</div>

      </main>
      </div>
        <Footer/>
   </div>
    
  );
};
