import React, { useRef, useState, useEffect } from "react";
import Header from '../../components/header/header.prod.jsx';
import Footer from '../../components/footer/footer.prod.jsx';
import {Card, Avatar, Tabs, Breadcrumb} from 'flowbite-react';
import { HomeIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import Graph from '../../components/graph'; // Graph 컴포넌트가 있는 경로로 변경해야 합니다.

export const AboutgPage = (props) => {

  return (
    <div>
      
        <Header isLogin={props.isLogin} userName={props.userName} userEmail={props.userEmail} userImage={props.userImage} />
        <div class="mx-auto w-full max-w-screen-xl">
        <main
		class="my-1 pt-2 pb-2 px-4 md:px-10 flex-1 rounded-l-lg
		transition duration-500 ease-in-out overflow-y-auto">

<Breadcrumb class="pt-6 md:px-4" aria-label="Default breadcrumb example">
  <Breadcrumb.Item
  >
    메인
  </Breadcrumb.Item>
  <Breadcrumb.Item>
  활동 기록
  </Breadcrumb.Item>
</Breadcrumb>
<h3
					class="flex items-center pt-6 md:px-4 text-3xl font-bold
					capitalize dark:text-gray-300">
            
					<span>활동 기록</span>
				</h3>
<p class="flex items-center py-4 md:px-4 font-semibold text-lg
					capitalize dark:text-gray-300">
           활동을 기록해요</p>

           <div className="bg-white">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">2023.07.13</h2>
          <p className="mt-4 text-gray-500">
            사이트 오픈을 했습니다
            
          </p>
        </div>
        <div className="">
          <img
            src="/log/IMG_3482.jpeg"
            alt="이미지"
            className="rounded-lg bg-gray-100 w-96"
          />
        </div>
      </div>
    </div>
    <div className="bg-white">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">2023.08.11</h2>
          <p className="mt-4 text-gray-500">
            마이크로비트 활용법을 배웠습니다
          </p>
        </div>
        <div className="">
          <img
            src="https://i.ibb.co/xh5bVXR/image.png"
            alt="이미지"
            className="rounded-lg bg-gray-100 w-96"
          />
        </div>
      </div>
    </div>
    <div className="bg-white">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">2023.08.18</h2>
          <p className="mt-4 text-gray-500">
            입력장치,출력장치의 개념과 변수,상수,난수를 알아보고 만보기와 가위바위보 게임 코드를 작성하는 실습하고 테스트 해보았다.<br/>
            <strong>실습3 코드는 로고를 누른 시간을 초단위로 재기 위해서 제작한 코드다.</strong>
          </p>
        </div>
        <div className="">
          <img
            src="https://i.ibb.co/FBxFBm4/image.png"
            alt="이미지"
            className="rounded-lg bg-gray-100 w-96"
          />
        </div>
      </div>
    </div>
    <div className="bg-white">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">2023.08.22</h2>
          <p className="mt-4 text-gray-500">
            온도센서, 조도센서, 버저, 자력계를 활용해보았다.<br/>
            결과물 다운로드를 위한 레포지토리:<br/><br/>
            <a href="https://github.com/10kwon/schoolMicrobit-0822" class="mt-3 px-3 bg-blue-500 font-bold text-white text-center m-auto py-2 rounded-xl transform ease-in duration-100 active:scale-95 hover:bg-blue-700">
              온도센서 실습</a>
              <a href="https://github.com/10kwon/schoolMicrobit-0822-2" class="mt-3 px-3 bg-blue-500 font-bold text-white text-center m-auto py-2 rounded-xl transform ease-in duration-100 active:scale-95 hover:bg-blue-700">
              조도센서 실습</a>
              <a href="https://github.com/10kwon/schoolMicrobit-08223" class="mt-3 px-3 bg-blue-500 font-bold text-white text-center m-auto py-2 rounded-xl transform ease-in duration-100 active:scale-95 hover:bg-blue-700">
              나침반 실습</a>   
          </p>
        </div>
        <div className="">
          <img
            src="https://i.ibb.co/nsvPCgb/image.png"
            alt="이미지"
            className="rounded-lg bg-gray-100 w-96"
          />
        </div>
      </div>
    </div>
    <div className="bg-white">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">2023.08.29</h2>
          <p className="mt-4 text-gray-500">
            입/출력 장치 및 통신을 배우고 소음 측정기와 라디오 주파수 활용 실습을 하였다.
          </p>
        </div>
        <div className="">
          <img
            src="https://i.ibb.co/8z44sg8/code.png"
            alt="이미지"
            className="rounded-lg bg-gray-100 w-96"
          />
        </div>
      </div>
    </div>
    <div className="bg-white">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">2023.09.05</h2>
          <p className="mt-4 text-gray-500">
            RC카를 Micro.bit을 통해 제작했습니다.
            수행평가 페이지를 참고하세요.
          </p>
        </div>
      </div>
    </div>
    <div className="bg-white">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">2023.09.15</h2>
          <p className="mt-4 text-gray-500">
           [OPP Q&A] 시리얼 통신에 한줄블럭을 추가한 이유? : 수만 전송하면 2591450 등으로 표출되어 그래프 표시가 어렵기 때문에 줄바꿈이 필요함<br/>
           초음파 센서의 값을 감지하여 멈추는 RC카를 제작하면서, 후진 시 경고음 (삐-)이 나도록 수정하였다.
          </p>
        </div>
      </div>
    </div>
    <div className="bg-white">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">2023.10.06</h2>
          <p className="mt-4 text-gray-500">
          적외선 센서를 이용하여 흰 바탕 위, 검정색 직선, 곡선등 라인을 따라가는 라인 트래킹봇을 만들었다. 기존 코드(수행2)와의 차이점이라면, 초음파 센서 대신 적외선 센서를 사용하여 보다 광범위한 인식 범위를 제공했고, Grayscale (음영)을 인식하여 정확하게 인식할 수 있게 하였다.
 <br/>
 적외선 센서 Q1. 흰색 면일 경우와 검정색 면에서 감지되는 값은 어떻게 다른가? : 흰색 면일 경우 3760-3770 정도의 값이 나왔고 검정색 면일 경우 380-800 정도의 값이 나왔다.
          </p>
        </div>
        <div className="">
          <img
            src="https://i.ibb.co/YTzM6YG/image.png"
            alt="이미지"
            className="rounded-lg bg-gray-100 w-96"
          />
          <Graph />
        </div>
      </div>
    </div>
    
      </main>
      </div>
        <Footer/>
   </div>
    
  );
};
